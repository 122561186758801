import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';

export function setModoTela(modo, registro = {}) {
    return {
        type: 'SAFRA_MODO_TELA',
        payload: {
			modo: modo,
			registro: {
				...registro,
				area: FormatUtils.formatarValorTela(registro.area, 2),
				densidade: FormatUtils.formatarValorTela(registro.densidade, 0),
				espacamento: FormatUtils.formatarValorTela(registro.espacamento, 0),
				produtividade: FormatUtils.formatarValorTela(registro.produtividade, 1),
				data_semeadura: DateFormat.formatarDataTelaParaSql(DateFormat.formatarDataSqlParaTela(registro.data_semeadura)),
				data_colheita: DateFormat.formatarDataTelaParaSql(DateFormat.formatarDataSqlParaTela(registro.data_colheita))
			}
		}
    };
}
export function initForm(registro = {}) {
    return [
        initialize('safraForm', {
			...registro,
			area: FormatUtils.formatarValorTela(registro.area, 2),
			densidade: FormatUtils.formatarValorTela(registro.densidade, 0),
			espacamento: FormatUtils.formatarValorTela(registro.espacamento, 0),
			produtividade: FormatUtils.formatarValorTela(registro.produtividade, 1),
			data_semeadura: DateFormat.formatarDataTelaParaSql(DateFormat.formatarDataSqlParaTela(registro.data_semeadura)),
			data_colheita: DateFormat.formatarDataTelaParaSql(DateFormat.formatarDataSqlParaTela(registro.data_colheita))
		})
    ];
}

export function setAguardando(aguardando) {
    return {
        type: 'SAFRA_SET_AGUARDANDO',
        payload: aguardando
    };
}

export function getLista() {
    const request = axios.get(`${consts.API_URL}/safra?id_safra_tipo=1`);
    return {
        type: 'SAFRA_LISTADO',
        payload: request
    };
}

export function salvar(registro) {
    return (dispatch, getState) => {
		if (!registro.id) {
	        axios.post(`${consts.API_URL}/safra`, {
				...registro,
				area: FormatUtils.formatarValorBanco(registro.area, 2),
				densidade: FormatUtils.formatarValorBanco(registro.densidade, 0),
				espacamento: FormatUtils.formatarValorBanco(registro.espacamento, 0),
				produtividade: FormatUtils.formatarValorBanco(registro.produtividade, 1),
				id_usuario_inclusao: getState().auth.usuario.id,
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		} else {
			axios.put(`${consts.API_URL}/safra`, {
				...registro,
				area: FormatUtils.formatarValorBanco(registro.area, 2),
				densidade: FormatUtils.formatarValorBanco(registro.densidade, 0),
				espacamento: FormatUtils.formatarValorBanco(registro.espacamento, 0),
				produtividade: FormatUtils.formatarValorBanco(registro.produtividade, 1),
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		}
    };
}

export function excluir(registro) {
    return dispatch => {
        axios.delete(`${consts.API_URL}/safra?id=${registro.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista());
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function getListaProdutor() {
    const request = axios.get(`${consts.API_URL}/produtor/listarSelect`);
    return {
        type: 'SAFRA_PRODUTOR_SELECT_LISTADO',
        payload: request
    };
}

export function getListaArea() {
    const request = axios.get(`${consts.API_URL}/area/listarSelect`);
    return {
        type: 'SAFRA_AREA_SELECT_LISTADO',
        payload: request
    };
}

export function getListaEspecie() {
    const request = axios.get(`${consts.API_URL}/especie/listarSelect`);
    return {
        type: 'SAFRA_ESPECIE_SELECT_LISTADO',
        payload: request
    };
}

export function imprimir(safra) {

	return async (dispatch, getState) => {

		let registro = {
			nome_produtor: safra.nome_produtor,
			nome_area: safra.nome_area,
			nome_especie: safra.nome_especie,
			area: `${FormatUtils.formatarValorTela(safra.area || 0, 2)} - hectares`,
			data_semeadura: DateFormat.formatarDataSqlParaTela(safra.data_semeadura),
			data_colheita: DateFormat.formatarDataSqlParaTela(safra.data_colheita),
			produtividade: `${FormatUtils.formatarValorTela(safra.produtividade || 0, 2)}`,
			manejos: [],
			custoTotal: 0
		}

		let listaTipoManejo = [];
		await axios.get(`${consts.API_URL}/safraManejoTipo/listarSelect`).then(result => {
			listaTipoManejo = result.data;
		}).catch(ex => {
			console.log(ex);
		});

		let listaManejo = []
		await axios.get(`${consts.API_URL}/safraManejo?id_safra=${safra.id}`).then(result => {
			listaManejo = result.data;
		}).catch(ex => {
			console.log(ex);
		});
	

		for (let i = 0; i < listaManejo.length; i++) {
			const manejo = listaManejo[i];

			let registroManejo = {
				area: `${FormatUtils.formatarValorTela(manejo.area || 0, 2)} - hectares`,
				data_aplicacao: DateFormat.formatarDataSqlParaTela(manejo.data_aplicacao),
				descricao: manejo.descricao,
				descricao_tipos: manejo.descricao_tipos,
				densidade: `${FormatUtils.formatarValorTela(manejo.densidade || 0, 0)}`,
				espacamento: `${FormatUtils.formatarValorTela(manejo.espacamento || 0, 2)} cm`,
				populacao: `${FormatUtils.formatarValorTela(manejo.populacao || 0, 0)} ${manejo.unidade_medida_populacao || ''}`,
				produtos: [],
				invasores: []
			}

			let listaProdutos = [];
			await axios.get(`${consts.API_URL}/safraManejoProduto?id_safra_manejo=${manejo.id}`).then(result => {
				listaProdutos = result.data;
			}).catch(ex => {
				console.log(ex);
			});

			for (let j = 0; j < listaProdutos.length; j++) {
				const produto = listaProdutos[j];

				const custoTotal = parseFloat(produto.custo || 0) * parseFloat(manejo.area || 0);
				registro.custoTotal += custoTotal;
				
				registroManejo.produtos.push({
					custo: `R$ ${FormatUtils.formatarValorTela(produto.custo || 0, 2)} por hectare`,
					custo_total: `R$ ${FormatUtils.formatarValorTela(custoTotal, 2)}`,
					dose: `${FormatUtils.formatarValorTela(produto.dose || 0, 3)} ${produto.nome_unidade_medida || ''}`,
					formula: produto.formula,
					nome_produto: produto.nome_produto,
					nome_produto_tipo: produto.nome_produto_tipo,
					nome_tipo: produto.nome_tipo
				});
			}

			let listaInvasores = []
			await axios.get(`${consts.API_URL}/safraManejoInvasor?id_safra_manejo=${manejo.id}`).then(result => {
				listaInvasores = result.data;
			}).catch(ex => {
				console.log(ex);
			});

			for (let j = 0; j < listaInvasores.length; j++) {
				const invasor = listaInvasores[j];
				
				registroManejo.invasores.push({
					tipo: invasor.nome_doenca ? 'Doença' : (invasor.nome_planta_invasora ? 'Planta Invasora' : 'Praga'),
					nome: invasor.nome_doenca || invasor.nome_planta_invasora || invasor.nome_praga
				});
			}

			registro.manejos.push(registroManejo);
			
		}

		registro.custoTotal = `R$ ${FormatUtils.formatarValorTela(registro.custoTotal, 2)}`;

		axios.post(`${consts.API_URL}/relatorioSafra/gerarPdf`, {
			registros: [ registro ],
			mostrarCustos: true
		}).then(resp => {
			window.open(`${consts.URL}/${resp.data}`, '_blank');
		}).catch(ex => {
			console.log(ex);
		});

	}
}
